<template>
  <div class="componentSty">
    <div class="componentSty_content">
      <div class="componentSty_content_title">
        <el-form inline size="small">
          <el-form-item label="充电站">
            <el-select
              v-model="conditions.stationId"
              clearable
              filterable
              @change="getPileOptions"
            >
              <el-option
                :key="item.id"
                v-for="item in stationOptions"
                :value="item.id"
                :label="item.stationName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="充电桩">
            <el-select
              v-model="conditions.pileId"
              placeholder="请选择充电站"
              :disabled="!this.conditions.stationId"
              clearable
              filterable
            >
              <el-option
                :key="item.id"
                v-for="item in pileOptions"
                :value="item.id"
                :label="item.pileName"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="日期">
            <el-date-picker
              v-model="date"
              type="daterange"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" class="buttons" @click="handleSearch"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item style="float: right">
            <el-dropdown
              trigger="click"
              style="margin-right: 10px"
              @command="handleCommand"
            >
              <el-button type="primary">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="a">全部导出</el-dropdown-item>
                <el-dropdown-item command="b">批量导出</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>
        </el-form>
      </div>
      <div class="componentSty_content_table" style="top: 90px; border: none">
        <el-table
          border
          :data="tableList"
          show-summary
          :summary-method="getSummaries"
          ref="tableRef"
          row-key="transportCode"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55"
            reserve-selection
          ></el-table-column>
          <el-table-column type="index" :index="indexMethod"> </el-table-column>
          <el-table-column prop="pileName" label="充电桩名称"></el-table-column>
          <el-table-column prop="pileId" label="充电桩编号"></el-table-column>
          <!--<el-table-column prop="plate" label="车牌号"></el-table-column>
          <el-table-column prop="driverName" label="司机名称"></el-table-column>-->
          <el-table-column
            prop="rechargeBegin"
            label="开始充电时间"
          ></el-table-column>
          <el-table-column
            prop="rechargeEnd"
            label="结束充电时间"
          ></el-table-column>
          <el-table-column
            prop="durationMinute"
            label="充电时长(分钟)"
          ></el-table-column>
          <el-table-column prop="kwh" label="充电量（度）"></el-table-column>
          <el-table-column
            prop="kwhAmount"
            label="充电金额（元）"
          ></el-table-column>
          <el-table-column
            prop="totalAmount"
            label="充电服务金额（元）"
          ></el-table-column>
        </el-table>
        <Pagination
          :total="total"
          :page="conditions.page"
          :pageSize="conditions.limit"
          @paging="getPaging"
        />
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import Pagination from "@/components/pagination.vue";
export default {
  data() {
    return {
      conditions: {
        page: 1,
        limit: 10,
        stationId: "",
        pileId: "",
      },
      date: [],
      stationOptions: [],
      pileOptions: [],
      total: 0,
      tableList: [],
      totalsData: {},
    };
  },
  components: {
    Pagination,
  },
  created() {
    this.getStationOptions();
    this.getList();
  },
  methods: {
    async getStationOptions() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/ChargeStation/all",
        "get",
        {}
      );
      this.stationOptions = data.data;
    },
    async getPileOptions() {
      if (this.conditions.stationId) {
        const { data } = await this.$http(
          "api1",
          "/api/asset/ChargePile/allByStationId",
          "get",
          {
            stationId: this.conditions.stationId,
          }
        );
        this.pileOptions = data.data;
      } else {
        this.pileOptions = [];
        this.conditions.pileId = "";
      }
    },
    indexMethod(index) {
      return (this.conditions.page - 1) * this.conditions.limit + index + 1;
    },
    getCondition() {
      let that = this;
      return {
        begin: that.date[0] ? new dayjs(that.date[0]).format("YYYY-MM-DD") : "",
        end: that.date[1] ? new dayjs(that.date[1]).format("YYYY-MM-DD") : "",
      };
    },
    getPaging(val) {
      this.conditions.page = val.page;
      this.conditions.limit = val.pageSize;
      this.getList();
    },
    async getList() {
      const { data } = await this.$http(
        "api1",
        "/api/asset/RechargeRecord/list",
        "get",
        { ...this.conditions, ...this.getCondition() }
      );
      console.log(data);
      if (data.code != 0) return this.$message.error(data.msg);
      this.tableList = data.data.list;
      this.total = data.data.totalCount;
      this.totalsData = data.summary;
    },
    handleSearch() {
      this.conditions.page = 1;
      this.getList();
    },
    async handleCommand(command) {
      if (command == "a") {
        this.$download(
          "post",
          "/api/asset/RechargeRecord/listExport",
          this.conditions
        );
      } else if (command == "b") {
        if (!this.checkedIds || this.checkedIds.length == 0) {
          return this.$message.error("请选择要导出的数据");
        }
        this.$download("post", "/api/asset/RechargeRecord/listExport", {
          ids: this.checkedIds.toString(),
        });
      }
    },
    handleSelectionChange(val) {
      this.checkedIds = val.map((item) => item.id);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 2) {
          sums[index] = "合计";
          return;
        } else if (index === 4) {
          sums[index] = "充电次数" + this.totalsData.chargeCount + "次";
          return;
        } else if (index === 5) {
          sums[index] = "充电时长" + this.totalsData.chargeHour + "小时";
          return;
        } else if (index === 6) {
          sums[index] = "充电量" + this.totalsData.kwh + "度";
          return;
        } else if (index === 7) {
          sums[index] = "充电金额" + this.totalsData.kwhAmount + "元";
          return;
        } else if (index === 8) {
          sums[index] = "充电服务金额" + this.totalsData.serviceAmount + "元";
          return;
        } else if (index === 9) {
          sums[index] = "充电总金额" + this.totalsData.totalAmount + "元";
          return;
        } else sums[index] = "";
      });

      return sums;
    },
  },
};
</script>
<style lang="scss" scoped></style>
